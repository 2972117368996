// Theme
export const THEME_SET = 'THEME_SET';
export const THEMES = {
  DEFAULT: 'DEFAULT',
  DARK: 'DARK',
  LIGHT: 'LIGHT',
  BLUE: 'BLUE',
  GREEN: 'GREEN',
  INDIGO: 'INDIGO'
};

// Auth
export const AUTH_SIGN_OUT = 'AUTH_SIGN_OUT';
export const AUTH_SIGN_IN_REQUEST = 'AUTH_SIGN_IN_REQUEST';
export const AUTH_SIGN_IN_SUCCESS = 'AUTH_SIGN_IN_SUCCESS';
export const AUTH_SIGN_IN_FAILURE = 'AUTH_SIGN_IN_FAILURE';
export const AUTH_SIGN_UP_REQUEST = 'AUTH_SIGN_UP_REQUEST';
export const AUTH_SIGN_UP_SUCCESS = 'AUTH_SIGN_UP_SUCCESS';
export const AUTH_SIGN_UP_FAILURE = 'AUTH_SIGN_UP_FAILURE';
export const AUTH_RESET_PASSWORD_REQUEST = 'AUTH_RESET_PASSWORD_REQUEST';
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS';
export const AUTH_RESET_PASSWORD_FAILURE = 'AUTH_RESET_PASSWORD_FAILURE';
export const AUTH_CHANGE_PASSWORD_REQUEST = 'AUTH_CHANGE_PASSWORD_REQUEST';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_FAILURE = 'AUTH_CHANGE_PASSWORD_FAILURE';

// Reco Bars
export const RECOBARS_ALL_RESULT = 'RECOBARS_ALL_RESULT';
export const RECOBARS_CREATE_RESULT = 'RECOBARS_CREATE_RESULT';
export const RECOBARS_MUTATION_RESULT = 'RECOBARS_MUTATION_RESULT';

// Routes
export const ROUTE_APP_CLOWNS = '/clowns';
export const ROUTE_APP_DASHBOARD = '/app/showrooms';
export const ROUTE_APP_NOTES = '/opmerkingen';
export const ROUTE_APP_KINDEREN = '/kinderen';
export const ROUTE_APP_CHATROOMS = '/chatrooms';
export const ROUTE_APP_CHATMESSAGES = '/berichten';
export const ROUTE_AUTH_LOGIN = '/auth/login';
export const ROUTE_APP_CAMPAIGNS = '/campaigns';
export const ROUTE_APP_HOTRELEASES = '/app/hotreleases';
export const ROUTE_APP_RECOBARS_NEW = '/app/recobars/new';
export const ROUTE_APP_RECOBARS_EDIT = '/app/recobars/edit';
export const ROUTE_APP_PRODUCTS_DETAIL = '/app/products/:id';
export const ROUTE_AUTH_RESET_PASSWORD = '/auth/reset-password';
export const ROUTE_APP_HOTRELEASES_EDIT = '/app/hotreleases/edit';
export const ROUTE_AUTH_COMPLETE_PASSWORD = '/auth/complete-password';
export const ROUTE_APP_NEW_TAG_PROFILE = '/app/tag-profiles/new-tag-profile';
export const ROUTE_AUTH_RESET_PASSWORD_SUBMIT = '/auth/reset-password-submit';
