import React from "react"
import { ThemeProvider } from "styled-components/macro"
import { create } from "jss"
import { useSelector } from "react-redux"
import DateFnsUtils from "@date-io/date-fns"
import { isBrowser } from "../utils"

import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles"

import createTheme from "../theme"

const jss = create({
  ...jssPreset(),
  insertionPoint: isBrowser()
    ? document.getElementById("jss-insertion-point")
    : null,
})

const Providers = ({ children }) => {
  const theme = useSelector(state => state.themeReducer)

  // console.log("theme",theme.currentTheme)

  return (
    <StylesProvider jss={jss}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
          <ThemeProvider theme={createTheme(theme.currentTheme)}>
            {children}
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  )
}

export default Providers
