import * as types from '../../constants';

export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      // console.log("USER LOGGED IN", actions.user)
      return {
        ...state,
        user: actions.user
      };

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined
      };

    case types.AUTH_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        reset: actions.email
      };

    default:
      return state;
  }
}
